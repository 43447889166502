import Head from 'next/head';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';

export const EditHeader = ({ title, id, navigateAway }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Typography color="error" variant="body1" component="h1" fontWeight={700}>
      {title} # {id}
    </Typography>
    <Button onClick={navigateAway} variant="contained" color="error">
      Exit
    </Button>
  </Box>
);

EditHeader.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  navigateAway: PropTypes.func.isRequired,
};

const Header = ({ title }) => (
  <Head>
    <title>{title} | Cortina Kitchens Inc.</title>
    <meta name="description" content={`${title} - Cortina Kitchens Inc.`} />
    <link rel="icon" href="/favicon.ico" />
  </Head>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
