import { Container } from '@mui/material';
import Header from '@/components/Header';
import { useAuth } from '@/contexts/AuthContext';
import { Sidebar } from '@/components/Navigation';
import { CortinaLogo } from '@/components/Logos';
import Copyright from '@/components/Copyright';

export default function Home() {
  const { isAuthenticated, user, groups } = useAuth();
  return (
    <Container sx={{ height: '100%' }} maxWidth="xl">
      <Header title="Home" />
      <Sidebar isAuthenticated={isAuthenticated} user={user} groups={groups} />
      <CortinaLogo />
      <Copyright />
    </Container>
  );
}
