import { ActionButton, ActionsContainer } from '@/components/Actions';

export const defaultFilters = ['none', 'today', 'week', 'month', 'year'];

export const SOData = {
  wo: '',
  so: '',
  ck: '',
  office_notes: '',
  shop_notes: '',
};

export const SOFields = ['wo', 'so', 'ck', 'office_notes', 'shop_notes', 'created', 'completed'];

export const SOExclude = ['id', 'wo', 'so', 'ck', 'created', 'completed'];

export const BOData = {
  wo: '',
  ck: '',
  description: '',
  notes: '',
};

export const BOFields = [
  'wo',
  'ck',
  'description',
  'notes',
  'created',
  'updated',
  'verifier',
  'parts_given_to',
];

export const QCCFields = [
  'wo',
  'field_manager',
  'inspection_date',
  'customer',
  'lot',
  'project',
  'phase',
  'name',
  'upload',
  'created',
];

export const CustomData = {
  wo: '',
  name: '',
  cabinets: '',
  // location: ''
};

export const CustomOrderData = {
  wo: '',
  week: 0,
  projected_install_date: null,
  order_state: 0,
  batch_type: 0,
};

export const DoorData = {
  wo: '',
  ck: '',
  supplier: '',
  current: 0,
  total: 0,
  original_location: '',
  notes: '',
};

export const DoorOrderedData = {
  wo: '',
  door_style: '',
  door_species: '',
  door_color: '',
  quantity: 0,
  notes: ''
};

export const DovertailData = {
  wo: '',
  name: '',
  species: '',
  quantity: 0,
  anticipated_at: null,
  ship_at: null,
};

export const MouldingData = {
  wo: '',
  tag: '',
  species: '',
  quantity: 0,
  anticipated_at: null,
  ship_at: null,
};

export const CustomOrderFields = [
  'wo',
  'week',
  'projected_install_date',
  'order_state',
  'batch_type',
];

export const DoorFields = [
  'wo',
  'ck',
  'supplier',
  'current',
  'total',
  'original_location',
  'location',
  'notes',
  'verifier',
  'created',
  'updated',
];

export const DoorExcluded = ['id', 'notes', 'original_location', 'created', 'updated'];

export const OrmontDoorExcluded = [
  'id',
  'notes',
  'original_location',
  'created',
  'updated',
  'is_requested',
  'is_transferred',
];

export const Suppliers = ['Rinova', 'Executive', 'Onbord', 'Vito', 'Unknown'];

export const PanelData = {
  wo: '',
  total: '',
  notes: '',
};

export const PanelExcluded = ['notes', 'created'];

export const ShippingData = {
  wo: '',
  skids: '',
  boxes: '',
  assembler: '',
  assemblyLine: '',
};

export const ShippingExcluded = ['id', 'created'];

export const ShippingLabels = new Map([
  ['wo', 'Work Order'],
  ['skids', 'Skids'],
  ['boxes', 'Boxes'],
  ['assembler', 'Boxes By'],
  ['assembly_line', 'Panels By'],
]);

export const AssemblyData = {
  wo: '',
  name: '',
  cabinets: '',
};

export const LineData = {
  wo: '',
  name: '',
  cabinets: '',
  notes: '',
};

export const LineExcluded = ['id', 'verifier', 'notes', 'created', 'completed'];

export const OrderState = {
  NONE: 0,
  RUSH: 1,
  INSHOP: 2,
  INPROD: 3,
  FINISH: 4,
  VITO: 5,
  ALAN: 6,
  IVAN: 7,
  CHI: 8,
};

export const BatchType = {
  NONE: 0,
  BATCH1: 1,
  BATCH2: 2,
  BATCH3: 3,
  ADVANCED: 4,
  PRODUCTION: 5,
};

export const StatusType = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETE: 2,
};

export const PurchaseType = {
  MISC: 0,
  HOTEL: 1,
  OFFICE: 2,
  FUEL: 3,
  MEALS: 4,
  PHONE: 5,
  ENTERTAINMENT: 6,
  ETR_407: 7,
};

export const ServiceExtraStatusType = {
  PENDING: 0,
  APPROVED: 1,
  NOTAPPROVED: 2,
};

export const columnsBackOrder = (isAuthenticated, groups) => {
  return [
    { field: 'wo', headerName: 'WO', width: 100 },
    { field: 'ck', headerName: 'CK', width: 100 },
    { field: 'description', headerName: 'Description', width: 100, hide: true },
    { field: 'notes', headerName: 'Notes', width: 395 },
    {
      field: 'created',
      headerName: 'Date Reported',
      width: 125,
      renderCell: params => format(parseISO(params.row.created), 'MM-dd'),
    },
    {
      field: 'updated',
      headerName: 'Last Update',
      width: 100,
      renderCell: params =>
        params.row.complete !== null ? format(parseISO(params.row.updated), 'MM-dd HH:mm') : '',
    },
    {
      field: 'is_complete',
      headerName: 'Complete',
      width: 100,
      renderCell: params => <BooleanChip bool={params.row.is_complete} />,
    },
    {
      field: 'is_verified',
      headerName: 'Verified',
      width: 100,
      renderCell: params => <BooleanChip bool={params.row.is_verified} />,
    },
    {
      field: 'verifier',
      headerName: 'Verifier',
      width: 100,
    },
    {
      field: 'parts_given_to',
      headerName: 'Given To',
      width: 100,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 116,
      sortable: false,
      renderCell: params => (
        <ActionsContainer>
          <ActionButton
            icon={<Edit />}
            label="Edit Back Order"
            func={() => router.push(`/back-orders/edit/${params.row.id}`)}
            tip="Edit Back Order"
            cond={isAuthenticated}
          />
          <ActionButton
            icon={
              params.row.is_complete ? (
                <CancelIcon color="error" fontSize="8px" />
              ) : (
                <CheckCircleIcon color="success" fontSize="8px" />
              )
            }
            label="Update Back Order"
            func={() => openCD(params.row)}
            tip={params.row.is_complete ? 'Set Back Order Incomplete' : 'Set Back Order Complete'}
            cond={isAuthenticated}
          />
          <ActionButton
            icon={
              params.row.is_verified ? (
                <GppBadIcon color="error" />
              ) : (
                <GppGoodIcon color="success" />
              )
            }
            label="Verify Back Order"
            func={() => openCD2(params.row)}
            tip={params.row.is_verified ? 'Set Back Order Unverified' : 'Set Back Order Verified'}
            cond={isAuthenticated && params.row.is_complete && groups.includes('SuperMod')}
          />
        </ActionsContainer>
      ),
    },
  ];
};

export const parseData = async textString => {
  const delimiters = [',', '\t', '\n'];

  // Trim the input string to remove any leading or trailing whitespace
  textString = textString.trim();

  // Detect which delimiter is used in the string
  const delimiter = delimiters.find(delim => textString.includes(delim));

  // Split the string based on the detected delimiter
  if (delimiter) {
    return textString.split(delimiter).map(item => item.trim());
  } else {
    // If no delimiter is found, return the whole string as a single element array
    return [textString];
  }
};

export const sortByKey = (arr, key) => {
  return arr.sort((a, b) =>
    a[key].localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' }),
  );
};

export const alphanumericSort = (v1, v2) => {
  const regex = /(\d+)|(\D+)/g; // Regular expression to split numeric and non-numeric parts
  const v1Parts = v1.match(regex);
  const v2Parts = v2.match(regex);

  for (let i = 0; i < Math.min(v1Parts.length, v2Parts.length); i++) {
    const v1Part = v1Parts[i];
    const v2Part = v2Parts[i];

    // Compare numeric parts as numbers
    if (!isNaN(v1Part) && !isNaN(v2Part)) {
      const diff = parseInt(v1Part, 10) - parseInt(v2Part, 10);
      if (diff !== 0) return diff;
    }

    // Compare non-numeric parts as strings
    if (v1Part !== v2Part) {
      return v1Part.localeCompare(v2Part);
    }
  }

  // If all parts are equal, the shorter one comes first
  return v1Parts.length - v2Parts.length;
};

export const flexedCenter = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const flexedStart = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const flexedEnd = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const ScheduledEventState = {
  id: 0,
  wo: '',
  client: '',
  style: '',
  species: '',
  finish: '',
  code: '',
  date: null,
};

export const SelectedState = {
  dateStr: '',
  date: '',
  list: [],
  info: {},
};

export const AlertState = {
  message: '',
  severity: 'info',
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const statusDict = {
  // 4XX Client Errors
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot", // RFC 2324
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',

  // 5XX Server Errors
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
};

export const absenceOptions = ['None', 'Work from Home', 'Out of Office'];

export const timeFormat = () => 'hh:mm A';
export const dateFormat = () => 'ddd MMM D';

export const permsCheck = (isAuthenticated, user, groups, required) => {
  let results = true;
  if (isAuthenticated && user?.is_staff) return true;
  else
    required.map(group => {
      if (!groups.includes(group)) {
        results = false;
        return;
      }
    });
  return results;
};

export const checkGroups = (groups, required) => {
  if (!groups || !Array.isArray(groups) || groups.length === 0) return false; // No groups to check against
  if (!required || !Array.isArray(required) || required.length === 0) return true; // No required groups, allow access
  return groups.some(group => required.includes(group)); // Check if any of the user's groups match the required groups
};

export const cardOptions = ['Stephen', 'Dan', 'Gino', 'Paul', 'Niico'];

export const expenseOptions = [
  'Misc',
  'Hotel',
  'Office',
  'Fuel',
  'Meals',
  'Phone',
  'Entertainment',
  'ETR 407',
];

export const ccLimits = {
  card: -1,
  username: -1,
  merchant: 32,
  type: 255,
  amount: -1,
  upload: -1,
  created: -1,
};

export const RFormState = {
  to: '',
  from_field: '',
  created_at: '',
  kit: '',
  tag: '',
  lot: '',
  items: [],
  ref: '',
  ordered_by: '',
};

export const SOEData = () => {
  const now = new Date();
  return {
    wo: '',
    name: '',
    address: '',
    phone: '',
    email: '',
    price: '',
    notes: '',
    status: 0,
    created_at: now,
    updated_at: now,
    updated_by: '',
  };
};

export const VehicleFormState = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return {
    incident_date: now.toISOString().split('T')[0],
    incident_time: `${hours}:${minutes}`,
    employee_id: 0,
    full_name: '',
    full_name_passengers: '',
    location: '',
    involved_vehicle_count: 0,
    property_damaged: false,
    property_damage_value: '',
    // vehicle_type: '',
    vehicle_make: '',
    vehicle_model: '',
    vehicle_year: '',
    vehicle_license_plate: '',
    vehicle_drivers_license: '',
    vehicle_insurance_provider: '',
    vehicle_insurance_policy: '',
    vehicle_insurance_expiry: new Date().toISOString().split('T')[0],
    created_at: '',
  };
};

export const VehicleState = {
  vehicle_make: '',
  vehicle_model: '',
  vehicle_year: '',
  license_plate: '',
  drivers_license: '',
  insurance_provider: '',
  insurance_policy: '',
  insurance_expiry: new Date(),
  created_at: '',
};

export const QCCFormState = {
  wo: '',
  field_manager: '',
  inspection_date: null,
  customer: '',
  lot: '',
  project: '',
  phase: '',
  info: [],
  name: '',
  created: null,
  upload: null,
};

export const defaultExpenseSheet = {
  purpose: '',
  statement_number: '',
  pay_period_start: '',
  pay_period_end: '',
  name: '',
  department: '',
  department_id: '',
  position: '',
  ssn: '',
  manager: '',
  employee_id: '',
  expenses: [],
  created_at: '',
  updated_at: '',
};

export const defaultExpense = {
  date: null,
  account: '',
  description: '',
  kms_travelled: 0,
  kms_cost: 0.0,
  purchase_type: 0,
  tax_included: false,
  reported_price: 0.0,
  created_at: '',
  updated_at: '',
};

export const dictExpense = {
  Misc: 0,
  Hotel: 1,
  Office: 2,
  Fuel: 3,
  Meals: 4,
  Phone: 5,
  Entertainment: 6,
  '407 ETR': 7,
};

export const customStatusTypes = [
  'No Status',
  'Rush',
  'In Shop',
  'In Production',
  'Finished',
  'Flagged',
];

export const customStatusDict = {
  'No Status': 0,
  Rush: 1,
  'In Shop': 2,
  'In Production': 3,
  Finished: 4,
};

export const defaultPurchaseState = {
  card: '',
  username: '',
  merchant: '',
  type: '',
  amount: 0.0,
  upload: null,
};

export const defaultServiceSheetState = {
  schedule_date: null,
  employee: 0,
  start_at: null,
  end_at: null,
};
