import { useContext } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { ThemeContext } from '@/contexts/ThemeContext';

export const CortinaLogo = () => {
  const { mode } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'auto',
        height: 'calc(100vh - 128px)',
        fontFamily: 'Cormorant',
        textAlign: 'center',
      }}
    >
      <Image
        style={{
          width: '60rem',
          maxWidth: '100%',
          height: 'auto',
          filter: mode === 'dark' ? 'invert(1)' : 'none',
        }}
        src="/imgs/CK-Logo.png"
        alt="Logo"
        width={1012}
        height={195}
        priority
        fetchPriority="high"
      />
    </Box>
  );
};

export const CortinaLogoHeader = ({ styles = {} }) => {
  const { mode } = useContext(ThemeContext);
  return (
    <Image
      style={{
        ...styles,
        filter: mode === 'dark' ? 'invert(1)' : 'none',
      }}
      src="/imgs/CK-Logo.png"
      alt="Logo"
      width={1012}
      height={195}
      priority
      fetchPriority="high"
    />
  );
};

CortinaLogoHeader.propTypes = {
  styles: PropTypes.object,
};

export const NiicoLogoHeader = ({ styles = {} }) => {
  const { mode } = useContext(ThemeContext);
  return (
    <Image
      style={{
        ...styles,
        filter: mode === 'dark' ? 'invert(1)' : 'none',
      }}
      src="/imgs/NIICO-Logo.png"
      alt="Logo"
      width={1109}
      height={196}
      priority
      fetchPriority="high"
    />
  );
};

NiicoLogoHeader.propTypes = {
  styles: PropTypes.object,
};
