import { Box, Tooltip, Typography } from '@mui/material';

const Copyright = () => (
  <Box
    sx={{
      bgcolor: 'background.paper',
      pb: 3,
      mt: 'auto',
      textAlign: 'center',
    }}
  >
    <Tooltip title="Created By: Christopher Couture">
      <Typography variant="body2" color="text.secondary">
        © 1987 - {new Date().getFullYear()} Cortina Kitchens Inc.
      </Typography>
      <Typography variant="body2" color="text.secondary">
        All rights reserved.
      </Typography>
    </Tooltip>
  </Box>
);

export default Copyright;
